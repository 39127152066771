<!--  -->
<template>
  <div class="div">
    <div class="banner">
      <div class="word1">联系我们</div>
      <div class="bannersmall"></div>
      <div class="txt1">领先的智能化碳信息技术平台</div>
    </div>

    <div class="outer7">
      <div class="word7">
        <span class="small"></span>联系我们
        <span class="small smalls"></span>
      </div>
      <div class="inside">
        <div class="title BLANK">
          <div :class="index == 0 ? 'on' : ''" @click="onclick(0)">
            <span>联系方式</span>
          </div>
          <div :class="index == 1 ? 'on' : ''" @click="onclick(1)">
            <span>在线留言</span>
          </div>
        </div>
        <div class="left" v-if="index == 0">
          <ul>
            <!-- <li>
              <img src="../../assets/AboutUs/location.png" alt />
              <span>广州地址：{{ obj.location1 }}</span>
            </li>-->
            <li>
              <img src="../../assets/AboutUs/mailbox.png" alt />
              <span>邮箱：{{ obj.mailbox }}</span>
            </li>
            <!-- <li>
              <img src="../../assets/AboutUs/location.png" alt />
              <span>北京地址：{{ obj.location }}</span>
            </li>-->
            <li>
              <img src="../../assets/AboutUs/wx.png" alt />
              <span class="wx_span">
                微信公众号：{{ obj.Tencent }}
                <img
                  src="../../assets/4d77167f836ab6642938615837a766e.jpg"
                  alt
                />
                <img
                  class="wximg"
                  src="../../assets/AboutUs/10d891744554f89a69fba6967b6fa96.jpg"
                  alt
                />
              </span>
            </li>
            <li>
              <img src="../../assets/AboutUs//phone.png" alt />
              <span>联系电话：{{ obj.phone }}</span>
            </li>
            <li>
              <img src="../../assets/AboutUs/wx.png" alt />
              <span>微信公众号：天朗易从科技</span>
            </li>
            <!-- <li>
              <img src="../../assets/4d77167f836ab6642938615837a766e.jpg" alt />
            </li>-->
          </ul>
          <div class="right" v-if="index == 0">
            <img :src="img" alt />
            <span
              style="
                font-size: 16px;
                color: #666;
                margin-top: 20px;
                display: inline-block;
                margin-bottom: 20px;
              "
              >地址：{{ obj.location }}</span
            >
            <img :src="img1" alt />
            <span
              style="
                font-size: 16px;
                color: #666;
                margin-top: 20px;
                display: inline-block;
                margin-bottom: 20px;
              "
              >地址：{{ obj.location1 }}</span
            >
          </div>
        </div>
        <div class="onlineMessage" v-if="index == 1">
          <div class="title">
            感谢您对天朗易从科技（北京）有限公司的大力支持，请您在下方的表格内填上您信息以及您想要留给我们的信息。我们将会尽快处理，并最迟在1个工作日内回复您的信息。
          </div>
          <div class="form">
            <el-form
              ref="form"
              :model="sizeForm"
              label-width="80px"
              size="mini"
              :rules="rules"
            >
              <el-form-item label="公司名称" prop="company">
                <el-input v-model="sizeForm.company"></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="sizeForm.name"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="mail">
                <el-input v-model="sizeForm.mail"></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="telephone">
                <el-input v-model="sizeForm.telephone"></el-input>
              </el-form-item>
              <el-form-item label="留言内容" prop="message">
                <el-input
                  type="textarea"
                  maxlength="100"
                  v-model="sizeForm.message"
                  show-word-limit
                ></el-input>
              </el-form-item>

              <el-form-item size="large" class="BLANK">
                <el-button type="primary" @click="onSubmit('form')" class="but"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button
                type="primary"
                @click="onSubmit('form')"
                class="but BLANK1"
                >提交</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { log } from 'console';
// ContactAdd
import { ContactAdd } from "../../api/api";
import ditu from "../../assets/AboutUs/ditu.png";
import ditu1 from "../../assets/AboutUs/ditu1.png";
export default {
  data() {
    return {
      obj: {
        location: "北京市东城区方家胡同46号E316",
        location1: "广州市天河区天河路518号801房C01室",
        wx: "wx",
        phone: "010 - 5848 3926",
        mailbox: "sales@skyezone.com.cn",
        Tencent: "零碳纪",
        invite: "hr@skyezone.com.cn", //招聘邮箱
      },
      img: ditu,
      img1: ditu1,
      index: 1, //0是练习方式  //1联系我们
      sizeForm: {
        name: "", //姓名
        telephone: "", //电话
        mail: "", //邮箱
        company: "", //公司
        message: "", //留言
        source: 2,
      },
      rules: {
        mail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern:
              /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z.]{2,7})$/,
            message: "请输入正确的邮箱",
            trigger: "blur",
          },
        ],
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        message: [
          { required: true, message: "请输入留言内容", trigger: "blur" },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },

  components: {},
  mounted() {
    _hmt.push(["_trackEvent", "联系我们", "进入"]);
  },
  methods: {
    onclick(val) {
      this.index = val;
    },
    onSubmit(formName) {
      _hmt.push(["_trackEvent", "联系按钮", "点击"]);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ContactAdd(this.sizeForm).then((res) => {
            this.sizeForm = {
              name: "", //姓名
              telephone: "", //电话
              mail: "", //邮箱
              company: "", //公司
              message: "", //留言
              source: 2,
            };
            this.$message({
              message: "您的留言已提交，我们会尽快与您联系",
              type: "success",
            });
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
</style>
